import React from "react"
import PropTypes from "prop-types"
import tw from "twin.macro"

// Assets
import heartIcon from "@images/icons/icon-heart-black.svg"
import eyeIcon from "@images/icons/icon-eye-black.svg"

const IconCallout = ({ icon, text, inAccordion }) => {
  const iconImage =
    icon === "heart"
      ? heartIcon
      : icon === "eye"
      ? eyeIcon
      : icon === "doctor"
      ? "/images/doctor-outline-icon.svg"
      : null

  const maxWidthDesktop = inAccordion
    ? tw`xl:(max-w-[600px])`
    : tw`xl:(max-w-[700px])`

  return (
    <div
      css={[
        tw`flex items-center gradient35 py-5 px-[15px] relative rounded-br-[60px] md:(py-4 pl-[60px] mx-auto max-w-[600px]) xl:(pr-[35px] translate-x-[37.5px])`,
        maxWidthDesktop,
      ]}
    >
      <img src={iconImage} alt="" tw="mr-[18px] md:(absolute left-[-30px])" />
      {text}
    </div>
  )
}

export default IconCallout

IconCallout.propTypes = {
  icon: PropTypes.string.isRequired,
  text: PropTypes.object.isRequired,
  inAccordion: PropTypes.bool,
}
